import { TYPE_CLOSE_CHATBOX, TYPE_OPEN_CHATBOX, TYPE_TOGGLE_CHATBOX } from "app/redux/chats&notifications/chatsTypes";

export const actionOpenChatbox = (chatId) => ({
  type: TYPE_OPEN_CHATBOX,
  payload: { chatId },
});

export const actionCloseChatbox = (chatId) => ({
  type: TYPE_CLOSE_CHATBOX,
  payload: { chatId },
});

export const actionToggleChatbox = (chatId) => ({
  type: TYPE_TOGGLE_CHATBOX,
  payload: { chatId },
});
