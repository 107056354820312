import {orderPlacesError} from "app/redux/editOrder/reducers/_error";
import {ORDER_PLACES_ERROR__MISSING_PLACE} from "app/redux/editOrder/actionTypes";

export const deleteSinglePlace = (state, {placeId}) => {

  if(!state.orderPlaces[placeId]) {
    return orderPlacesError(state, { errCode: ORDER_PLACES_ERROR__MISSING_PLACE})
  }

  //TODO: add key validation (or not?)
  const { [placeId]: deletedPlace, ...orderPlaces } = state.orderPlaces;

  return {
    ...state,
    orderPlaces,
    orderPlacesKeys: state.orderPlacesKeys.filter(id=>id!==placeId),
  };
}
