import {combineReducers} from "redux"
import customizer from "app/redux/reducers/@customizer"
import auth from "app/redux/reducers/auth"
import navbarReducer from "app/redux/reducers/@navbar/Index";
import {notificationReducer} from "app/redux/reducers/notificationsAndMessages/notificationReducer";
import {editOrderReducer} from 'app/redux/editOrder/reducer';
import chatsReducer from "app/redux/chats&notifications";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbarReducer,
  notification: notificationReducer,
  editOrder: editOrderReducer,
  chats: chatsReducer,
})

export default rootReducer
