import {createChatEmployee} from "app/redux/chats&notifications/utlis/createChatEmployee";
import {createChatMessage} from "app/redux/chats&notifications/utlis/createChatMessage";

export const createChat = ({
  id,
  lastMessageDate,
  unreadCount,
  fromEmployeeId,
  fromEmployee,
  toEmployeeId,
  toEmployee,
  lastMessage,
  loggedEmployeeId
}) => ({
  chatId: id,
  employee: createChatEmployee(loggedEmployeeId===toEmployeeId ? fromEmployee : toEmployee),
  employeeId: loggedEmployeeId===toEmployeeId ? fromEmployeeId : toEmployeeId,
  unreadCount,
  lastMessageTime: lastMessageDate ? new Date(lastMessageDate).getTime() : 0,
  lastMessage: lastMessage ? createChatMessage(lastMessage) : null,
  newMessage: '',
  fileMessage: null,
  stats: {
    messagesCount: 0,
    oldestId: null,
    newestId: null,
    isReachOldest: false,
  },
});
