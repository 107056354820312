import axios from "axios";
import {BASE_URL_API} from "app/config/env";
import useQuery2 from "app/hooks/useQuery2";

const singleMessagePromise = (key, chatId, chatMessageId) => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/company-panel/chats/${chatId}/messages/${chatMessageId}`,
});

export const useSingleMessage = (chatId, chatMessageId) => useQuery2({
  queryKey: ['pc.chatMessage', chatId, chatMessageId],
  queryFn: singleMessagePromise,
});
