import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Layout } from "app/utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { PersistGate } from 'redux-persist/integration/react'
import {persistor, store} from "app/redux/storeConfig/store"
import {setupAxios} from "setupAxios";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
//
import "./index.scss"
import "./@fake-db"
import {setupYup} from "app/config/setupYup";
import {BrowserRouter} from "react-router-dom";
import ErrorBoundary from "app/core/ErrorBoundary";
import App from "App";


setupAxios(store);
setupYup();

// configureDatabase()
ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Spinner />}>
            <Layout>
              <App />
            </Layout>
          </Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
