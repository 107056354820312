import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/exchange`;

//pc = panel.company

export const exchangeRoutes = {
    'pc.exchange': `${prefix}`,
    'pc.exchange.show': (exchangeId=':exchangeId') => `${prefix}/${exchangeId}`,
    'pc.exchange.notice': (exchangeId = ':exchangeId', noticeId = ':noticeId') => `${prefix}/${exchangeId}/notice/${noticeId}`,
}
