export const reducerClearUnread = (state, { chatId }) => ({
  ...state,
  chats: {
    ...state.chats,
    [chatId]: {
      ...(state?.chats?.[chatId] ?? {}),
      unreadCount: 0,
    }
  }
})
