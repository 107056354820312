import React, { useContext } from "react";

const ChatContext = React.createContext({})

export const useChatContext = () => useContext(ChatContext)

export const ChatContextProvider = ({ chatId, children }) => (
  <ChatContext.Provider value={chatId}>
    {children}
  </ChatContext.Provider>
)
