import React, {useRef, useState} from "react";
import styled, {css} from "styled-components";
import {ChatList} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/Chats";
import {useDispatch} from "react-redux";
import {actionOpenChatbox} from "app/redux/chats&notifications/chatActions/actionChatbox";
import {useSelectChatboxes} from "app/redux/chats&notifications/selectors/selectChatboxes";
import useDimensions from "app/hooks/useWindowSize";
import Chatbox from "views/pages/companyPanel/conversations/Chatboxes/Chatbox";
import {route} from "app/config/urls/routes";
import {useTranslation} from "react-i18next";
import {Eye, MessageCircle} from "react-feather";
import HiddenChatbox from "views/pages/companyPanel/conversations/Chatboxes/HiddenChatbox";
import {actionToggleSidebar} from "app/redux/chats&notifications/chatActions/actionToggleSidebar";
import {useChatsInfo} from "app/crud/global/messages/getInfo";
import {Badge} from "reactstrap";
import useMatchPath from "app/hooks/useMatchPath";

const StyledContainer = styled.aside`
  width: 250px;
  top: 4.65rem;
  right: 0;
  bottom: 0;
  height: calc(100vh - 10rem);
  flex-direction: column;
  transition: opacity 0.25s ease, transform 0.25s ease;
  position: fixed;
  margin: 0.5em;
  border-radius: 1em;
`

const HoverContainer = styled.div`
  z-index: 11;

  ${({ isOpen, isHoverable }) => !isOpen && isHoverable && css`
    &:hover ${StyledContainer} {
      transform: translateX(0);
      opacity: 1;
      transition-delay: 0s;
    }

    ${StyledContainer} {
      transition-delay: 0.2s;
    }
  `}

  ${({ isOpen }) => !isOpen && css`
    ${StyledContainer} {
      transform: translateX(120%);
      opacity: 0;
    }
  `}

  a svg {
    fill: ${({ isOpen }) => isOpen && 'currentColor'};
  }
`

const ChatboxContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: ${({ isOpen }) => isOpen ? '270px' : '70px'};
  left: 260px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  z-index: 10;
  pointer-events: none;
`

const HiddenChatboxes = styled.div`
  > div {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    transition: opacity 0.2s ease;
  }

  &:hover > div, > div:hover {
    opacity: 1;
    pointer-events: auto;
  }
`

const ConversationPanelInner = () => {
  const { t } = useTranslation()
  const [isHoverable, setHoverable] = useState(false)
  const { chatboxes, isSidebarOpen: isOpen } = useSelectChatboxes()
  const { data } = useChatsInfo();
  const dispatch = useDispatch()
  const containerRef = useRef()
  const dimensions = useDimensions(containerRef, [chatboxes, isOpen])
  const chatboxCount = Math.floor(dimensions.width / 310)

  const displayedChatboxes = chatboxes?.slice(-chatboxCount)
  const hiddenChatboxes = chatboxes?.slice(0, -chatboxCount)

  const handleToggleSidebar = () => {
    setHoverable(false)
    dispatch(actionToggleSidebar)
  }

  const handleMouseLeave = () => {
    if (!isHoverable && !isOpen)
      setHoverable(true)
  }

  return (
    <>
      <div style={{ width: isOpen ? 230 : 0 }} className="flex-shrink-0 d-none d-lg-block" />
      <HoverContainer {...{ isHoverable, isOpen }}>
        <StyledContainer className="bg-theme flex-shrink-0 d-none d-lg-flex flex-column shadow-lg user-select-none zindex-1">
          <div className="position-relative small flex-grow-1">
            <div className="position-absolute left-0 top-0 h-100 w-100 overflow-auto px-50 py-75">
              <ChatList onChatSelect={(chatId) => dispatch(actionOpenChatbox(chatId))} />
            </div>
          </div>
          {!!hiddenChatboxes?.length && (
            <HiddenChatboxes className="px-2 py-75 cursor-default position-relative flex-shrink-0 font-weight-bold border-top">
              <Eye size={18} className="mr-50" />
              {hiddenChatboxes?.length} {t('generic.more')}...
              <div className="position-absolute w-100 bg-theme left-0 top-0 d-flex p-1 rounded-top flex-column small">
                {hiddenChatboxes?.map(({ id }) => <HiddenChatbox key={id} chatId={id} />)}
              </div>
            </HiddenChatboxes>
          )}
        </StyledContainer>

        <a
          className="rounded-circle position-fixed bottom-0 right-0 m-50 d-none d-lg-flex p-1 bg-theme shadow"
          onClick={handleToggleSidebar}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseLeave}
        >
          <div className="position-relative">
            {!!data?.hasUnreadConversations && (
              <Badge pill color="primary" className="badge-up">
                {data.unreadConversations}
              </Badge>
            )}
            <MessageCircle />
          </div>
        </a>
      </HoverContainer>

      <ChatboxContainer isOpen={isOpen} className="d-none d-lg-flex" ref={containerRef}>
        {displayedChatboxes?.map(({ id, open }) => <Chatbox key={id} chatId={id} open={open} />)}
      </ChatboxContainer>
    </>
  )
}

const ConversationPanel = () => {
  const match = useMatchPath({
    path: route['pc.conversations'],
    exact: false,
  })

  if (match) return null
  return <ConversationPanelInner />
}

export default ConversationPanel
