import { useEffect, useLayoutEffect, useState } from "react"

const useDimensions = (ref, deps) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!ref?.current) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        return
      }
      const dimensions = ref.current.getBoundingClientRect()
      setWindowSize({
        width: dimensions?.width,
        height: dimensions?.height,
      });
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [ref, ...deps])

  return windowSize
}

export default useDimensions
