import {COMPANY_PANEL} from "app/config/urls/prefixes";
const prefix = `${COMPANY_PANEL}/contractors`;

// pc = panel.company

export const contractorRoutes = {
  'pc.contractor': `${prefix}`,
  'pc.contractor.add': `${prefix}/add`,
  'pc.contractor.show': (id=':contractorId') => `${prefix}/${id}`,
  'pc.contractor.edit': (id=':contractorId') => `${prefix}/${id}/edit`,
  // contractor employee
  'pc.contractor.employee.show': (contractorId = ':contractorId', id=':employeeId') => `${prefix}/${contractorId}/employees/${id}`,
  'pc.contractor.employee.edit': (contractorId = ':contractorId', id=':employeeId') => `${prefix}/${contractorId}/employees/${id}/edit`,
  'pc.contractor.vehicle.show': (contractorId = ':contractorId', id=':vehicleId') => `${prefix}/${contractorId}/vehicles/${id}`,
  'pc.contractor.vehicle.edit': (contractorId = ':contractorId', id=':vehicleId') => `${prefix}/${contractorId}/vehicles/${id}/edit`,
}
