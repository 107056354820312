import {
  TYPE_ADD_NEW_MESSAGE,
  TYPE_CLEAR_CHATS,
  TYPE_CLEAR_UNREAD,
  TYPE_CLOSE_CHATBOX,
  TYPE_OPEN_CHATBOX,
  TYPE_SET_CHAT,
  TYPE_SET_CHATS,
  TYPE_SET_MESSAGES,
  TYPE_SET_PAGINATION,
  TYPE_SET_SEARCH,
  TYPE_SET_TMP_FILE,
  TYPE_SET_TMP_MESSAGE,
  TYPE_TOGGLE_CHATBOX,
  TYPE_TOGGLE_SIDEBAR
} from "app/redux/chats&notifications/chatsTypes";
import {reducerCreateChat} from "app/redux/chats&notifications/reducers/chat/reducerCreateChat";
import {reducerCreateChats} from "app/redux/chats&notifications/reducers/chat/reducerCreateChats";
import {reducerSetMessages} from "app/redux/chats&notifications/reducers/chatMessages/reducerSetMessages";
import {reducerSetSearch} from "app/redux/chats&notifications/reducers/chat/reducerSetSearch";
import {reducerSortChats} from "app/redux/chats&notifications/reducers/chat/reducerSortChats";
import {reducerSetNewTempMessage} from "app/redux/chats&notifications/reducers/chat/reducerSetNewTempMessage";
import {reducerSetNewMessage} from "app/redux/chats&notifications/reducers/chatMessages/reducerSetNewMessage";
import {reducerSetNewTmpFile} from "app/redux/chats&notifications/reducers/chat/reducerSetNewTmpFile";
import {reducerOpenChatbox} from "app/redux/chats&notifications/reducers/sidebar/reducerOpenChatbox";
import {reducerCloseChatbox} from "app/redux/chats&notifications/reducers/sidebar/reducerCloseChatbox";
import {reducerToggleChatbox} from "app/redux/chats&notifications/reducers/sidebar/reducerToggleChatbox";
import {reducerToggleSidebar} from "app/redux/chats&notifications/reducers/sidebar/reducerToggleSidebar";
import {reducerClearUnread} from "./reducers/chat/reducerClearUnread";
import {CHAT_PAGE_SIZE, reducerSetPagination} from "app/redux/chats&notifications/reducers/chat/reducerSetPagination";
import {reducerCloseChat} from "app/redux/chats&notifications/reducers/chat/reducerCloseChat";

const initialState = () => ({
  chats: {},
  chatMessages: {},
  search: '',
  chatsSorted: [],
  isSidebarOpen: false,
  chatboxes: [],
  pageSize: CHAT_PAGE_SIZE,
});

export const chatsReducer = (state = initialState(), {type, payload}) => {

  switch (type) {

    // CHATS
    case TYPE_SET_CHAT:
      return reducerSortChats(reducerCreateChat(state, payload));
    case TYPE_SET_CHATS:
      return reducerSortChats(reducerCreateChats(state, payload));
    case TYPE_SET_PAGINATION:
      return reducerSetPagination(state);
    case TYPE_SET_SEARCH:
      return reducerSetSearch(state, payload);
    case TYPE_SET_TMP_MESSAGE:
      return reducerSetNewTempMessage(state, payload);
    case TYPE_SET_TMP_FILE:
      return reducerSetNewTmpFile(state, payload);
    case TYPE_CLEAR_UNREAD:
      return reducerClearUnread(state, payload);
    case TYPE_CLEAR_CHATS:
      return reducerCloseChat(state, payload);
    // MESSAGES
    case TYPE_SET_MESSAGES:
      //console.log(true)
      return reducerSetMessages(state, payload);
    case TYPE_ADD_NEW_MESSAGE:
      return reducerSortChats(reducerSetNewMessage(state, payload));

    // CHAT SIDEBAR
    case TYPE_TOGGLE_SIDEBAR:
      return reducerToggleSidebar(state, payload);
    // CHATBOX
    case TYPE_OPEN_CHATBOX:
      return reducerOpenChatbox(state, payload);
    case TYPE_CLOSE_CHATBOX:
      return reducerCloseChatbox(state, payload);
    case TYPE_TOGGLE_CHATBOX:
      return reducerToggleChatbox(state, payload);
    case 'persist/REHYDRATE': {
      return {
        ...initialState(),
        ...state,
      };
    }
    // DEFAULT
    default:
      return state;
  }

}
