

export const reducerSetNewTmpFile = (state, {chatId, file}) => {

  return {
    ...state,
    chats: {
      ...state.chats,
      [chatId]: {
        ...state.chats[chatId],
        fileMessage: file,
      }
    }
  }
}
