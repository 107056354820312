import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-fill";
import {route} from "app/config/urls/routes";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Item = ({text, route, className}) => (
  <NavItem>
    <NavLink
      tag={Link}
      to={route}
      className={`btn p-1 font-weight-bold ${className}`}
    >
      {text}
    </NavLink>
  </NavItem>
);

Item.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string,
  className: PropTypes.string,
};

const PublicNavbar = ({transparent = false}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {token} = useSelector(({auth}) => auth.login);

  const sizeClasses = ['wcag-xsmall', 'wcag-small', 'wcag-normal', 'wcag-large', 'wcag-xlarge'];
  const getLvlOfFontSize = () => {
    for (let i = 0; i < sizeClasses.length; i++) {
      // console.log(i, sizeClasses[i], document.documentElement.classList.contains(sizeClasses[i]))
      if (document.documentElement.classList.contains(sizeClasses[i])) {
        return i;
      }
    }
    return 2;
  }
  const handleClickFontSmall = () => {
    const lvl = getLvlOfFontSize();
    console.log(lvl)
    if (lvl - 1 >= 0) {
      document.documentElement.classList.remove(sizeClasses[lvl]);
      document.documentElement.classList.add(sizeClasses[lvl - 1]);
    }
  }
  const handleClickFontBig = () => {
    const lvl = getLvlOfFontSize();
    console.log(lvl)
    if (lvl + 1 < sizeClasses.length) {
      document.documentElement.classList.remove(sizeClasses[lvl]);
      document.documentElement.classList.add(sizeClasses[lvl + 1]);
    }
  }
  const handleClickNormalSize = () => {
    const lvl = getLvlOfFontSize();
    document.documentElement.classList.remove(sizeClasses[lvl]);
  }
  const handleClickHighContrast = () => {
    document.documentElement.classList.toggle('wcag-high-contrast');
  }


  const toggle = () => setIsOpen(!isOpen);
  const isTransparent = transparent && !isOpen;
  const color = !isTransparent ? 'text-primary' : 'text-white'

  const { t } = useTranslation();

  return (
    <Sticky className="zindex-4">
      <Navbar light={!isTransparent} dark={isTransparent} className={isTransparent ? 'bg-transparent' : ''} expand="md">
        <NavbarBrand to="/" tag={Link}>
          {isTransparent && (
            <>
              <img src="https://api.sydig.com/images/logo/logo-s.png" alt="sydig logo" style={{height: '40px'}} />
              <img src="https://api.sydig.com/images/logo/logo-ydig.png" alt="sydig logo" style={{height: '40px'}} />
            </>
          )}
          {!isTransparent && <img src="https://api.sydig.com/images/logo/logo-black.png" alt="sydig logo" />}
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="w-100 mb-2 mb-md-0">
            <Item
              text="Firmy"
              route={route['public.company']}
              className={`btn px-2 ${color} ml-md-2 my-1 my-md-0`}
            />
            <Item
              text="Ogłoszenia"
              route={route['public.poster']}
              className={`btn px-2 ${color} ml-md-1`}
            />
            <div className="ml-auto mr-1" >
              {/* <LanguageSwitcher /> */}
            </div>
            <li className="nav-item d-flex">
              <div className="nav-link cursor-pointer d-flex ">
                <span onClick={handleClickNormalSize} className="cursor-pointer">
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-xsmall">80%</span>
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-small">90%</span>
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-large">110%</span>
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-xlarge">120%</span>
                </span>
                <div className="d-flex  align-items-end">
                  <h5 onClick={handleClickFontSmall} className="mb-25 cursor-pointer">A</h5>
                </div>
                <div className="d-flex  align-items-end">
                  <h2 onClick={handleClickFontBig} className="m-0 font-weight-bold px-1 cursor-pointer">A</h2>
                </div>
                {/*<Disc onClick={handleClickHighContrast} className="cursor-pointer" />*/}
              </div>
            </li>
            {token ? (
              <Item
                text="Panel"
                route={route['pc.dashboard']}
                className="btn-primary px-2 ml-auto text-white"
              />
            ) : (
              <>
                <Item
                  text="Rejestracja"
                  route={route['auth.register']}
                  className="btn-primary px-2 text-white my-1 my-md-0"
                />
                <Item
                  text={t("auth.login")}
                  route={route['auth.login']}
                  className="btn-primary px-2 text-white ml-md-1"
                />
              </>
            )}
          </Nav>
        </Collapse>
        <NavbarToggler onClick={toggle} className="ml-auto" />
      </Navbar>
    </Sticky>
  );
};

PublicNavbar.propTypes = {
  transparent: PropTypes.bool,
};

export default PublicNavbar;
