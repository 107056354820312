import i18n from 'i18next';
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";


i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'pl',
  ns: ['global'],
  defaultNS: 'global',
  debug: true,
  detection: {
    order: ['queryString', 'cookie'],
    cache: ['cookie']
  },
  interpolation: {
    escapeValue: false
  },
  backend: {
    // loadPath: `https://api.sydig.com/v1/translations/translations/{{lng}}/{{ns}}.json`,
    // ** BEGIN DEBUG **
    loadPath: ([lng]) => {
      return `https://api.sydig.com/v1/translations/translations/${lng === 'debug' ? 'pl' : '{{lng}}'}/{{ns}}.json`
    },
    parse: (data, lang) => {
      const parsed = JSON.parse(data)
      if (lang === 'debug') {
        for (const category in parsed)
          for (const [key, value] of Object.entries(parsed[category]))
            parsed[category][key] = value.replace(/[^\s]/g, '▒')
      }
      return parsed
    }
    // ** END DEBUG **
  }
});

export default i18n;
