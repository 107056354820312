
export const TYPE_SET_MESSAGES    = 'chats.messages.set';
export const TYPE_SET_CHAT        = 'chats.set';
export const TYPE_SET_CHATS       = 'chats.set.many';
export const TYPE_SET_SEARCH      = 'chats.set.search';
export const TYPE_SET_PAGINATION  = 'chats.set.pagination';
export const TYPE_SET_TMP_MESSAGE = 'chats.set.newTmpMessage';
export const TYPE_SET_TMP_FILE    = 'chats.set.newTmpFile';
export const TYPE_ADD_NEW_MESSAGE = 'chats.add.newMessage';
export const TYPE_CLEAR_UNREAD    = 'chats.clear.unread';
export const TYPE_CLEAR_CHATS     = 'chats.clear.chats';

export const TYPE_TOGGLE_SIDEBAR  = 'chats.toggle.sidebar';
export const TYPE_OPEN_CHATBOX    = 'chats.open.chatbox';
export const TYPE_CLOSE_CHATBOX   = 'chats.close.chatbox';
export const TYPE_TOGGLE_CHATBOX  = 'chats.toggle.chatbox';
