
export const createPlaceCargo = ({
  id = null,
  orderPlaceLoadingId = null,
  orderPlaceUnloadingId = null,
  placeLoadingId = null,
  placeUnloadingId = null,
  internalNo = 0,
  description = '',
  count = 0,
  width = 0,
  height = 0,
  length = 0,
  ldm = 0,
  name = 0,
  weight = 0,
  weightTotal = 0,
  adrPackingGroup = 0,
  tempLoadingFrom = 0,
  tempLoadingTo = 0,
  tempFrom = 0,
  tempTo = 0,
  adrClassId = 0,
  adrunNumberId = 0,
  packageType = {},
}) => {
  //console.log(placeUnloadingId,orderPlaceUnloadingId)
  return({
    id,
    placeLoadingId:placeLoadingId||orderPlaceLoadingId,
    placeUnloadingId:placeUnloadingId||orderPlaceUnloadingId,
    internalNo,
    description,
    count,
    width,
    height,
    length,
    ldm,
    name,
    weight,
    weightTotal,
    adrPackingGroup:adrPackingGroup?.id,
    _adrPackingGroupName:adrPackingGroup?.name,
    tempLoadingFrom,
    tempLoadingTo,
    tempFrom,
    tempTo,
    adrClassId,
    adrunNumberId,
    packageTypeId: packageType?.id,
    packageTypeName: packageType?.name,
    _isPlaceUnloadingSubmitting: false,
  })
}
