import axios from 'axios';
import useQuery2 from "app/hooks/useQuery2";
import {BASE_URL_API} from "app/config/env";

const getInfo = () => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/company-panel/chats/info`,
});

export const useChatsInfo = () => useQuery2({
  queryKey: ['globals.chats.count'],
  queryFn: getInfo,
  config: {
    refetchInterval: 15000,
    refetchOnWindowFocus: false,
  }
});
