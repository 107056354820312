
const compareTime = ( a, b ) => {
  if ( a.time < b.time ){
    return 1;
  }
  if ( a.time > b.time ){
    return -1;
  }
  return 0;
}

export const reducerSortChats = (state) => {
  const chatIds = Object.keys(state.chats);
  const chats = chatIds.map(chatId => ({ chatId, time: state.chats[chatId].lastMessageTime }));
  const sorted = chats.sort(compareTime);
  const idsSorted = sorted.map(({chatId})=>chatId);

  return {
    ...state,
    chatsSorted: idsSorted,
  };
}
