import {createSellerInfo} from "../utils/createSellerInfo";

export const setSellerInfo = (state, value) => {

  const sellerInfo = createSellerInfo(value);

  return {
    ...state,
    sellerInfo,
  };
}
