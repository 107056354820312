import {Route} from "react-router-dom";
import {ContextLayout} from "app/utility/context/Layout";
import React, {Suspense} from "react";
import Spinner from "components/@vuexy/spinner/Loading-spinner";

const rootRouteRender = (Component, fullLayout, isHorizontal) => props => (
  <ContextLayout.Consumer>
    {context => {
      let LayoutTag =
        fullLayout === true
          ? context.fullLayout
          : context.state.activeLayout === "horizontal" || isHorizontal
          ? context.horizontalLayout
          : context.VerticalLayout
      return (
        <LayoutTag {...props}>
          <Suspense fallback={<Spinner />}>
            <Component {...props} />
          </Suspense>
        </LayoutTag>
      )
    }}
  </ContextLayout.Consumer>
);


export const RootRoute = ({
  component: Component,
  fullLayout,
  isHorizontal,
  permission,
  ...props
}) => (
  <Route {...props} render={rootRouteRender(Component, fullLayout, isHorizontal)}/>
);
