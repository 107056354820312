import React from 'react';
import {withRouter} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {route} from "app/config/urls/routes";
import {APP_VERSION} from "app/config/env";
import {sendBugReport} from "app/crud/global/sendBugReport";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.lastPath = React.createRef();
    this.errorReportSent = React.createRef();
  }

  static getDerivedStateFromError(error) { // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  UNSAFE_componentWillUpdate(_prev, nextProps) {
    if(!nextProps?.hasError) {
      this.lastPath.current = this.props.location.pathname;
    }
  }

  async componentDidCatch(error, errorInfo) { // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    const err = {
      message: error?.message,
      screenWidth: window?.innerWidth || document?.documentElement?.clientWidth || document?.body?.clientWidth,
      screenHeight: window?.innerHeight|| document?.documentElement?.clientHeight|| document?.body?.clientHeight,
      language: navigator?.language,
      userAgent: navigator?.userAgent,
      appVersion: parseInt((APP_VERSION??0)*1000),
      url: this.props?.location?.pathname,
      prevUrl: this?.lastPath?.current,
      file: error?.fileName,
      details: JSON.stringify({
        columnNumber: error?.columnNumber,
        lineNumber: error?.lineNumber,
        platform: navigator?.platform,
        cookieEnable: navigator?.cookieEnabled,
        screenWidth: window?.screen?.width,
        screenHeight: window?.screen?.height,
      })
    };
    if(!this.errorReportSent.current) {
      this.errorReportSent.current = true;
      try {
        await sendBugReport(err);
      } catch (err) {
        console.error('Failed to send bug report')
      }
    }
  }
  render() {
    if (this.state.hasError)
      return (
        <div className="m-20">
          <QueryError isError isLoading={false} noButton>
            <a href={route['index']}>
              <button className="btn btn-light mt-4 px-10 btn-lg">Strona główna</button>
            </a>
          </QueryError>
        </div>
      )
    return this.props.children;
  }
}

const QueryError = ({ error, message, isError, isLoading, children, noButton }) => {
  const history = useHistory()
  if (isLoading || (!isError && isError!==undefined))
    return null;

  return (
    <div className="border bg-white px-10 py-15 rounded-lg text-center d-flex flex-column align-items-center w-100">
      <i className="fas fa-exclamation-triangle fa-3x" />
      {error?.response?.status === 404 ? (
        <>
          <h1 className="display-4 font-weight-bolder mt-4">Przepraszamy!</h1>
          <h2>Tej strony nie ma w naszym systemie</h2>
          {!noButton && (
            <button className="btn btn-primary btn-lg mt-8" onClick={() => history.push(route['/'])}>
              <i className="fas fa-arrow-left" /> Powrót do <b>traineron.com</b>
            </button>
          )}
        </>
      ) : (
        <>
          <h1 className="display-4 font-weight-bolder mt-4">Przepraszamy, coś poszło nie tak</h1>
          <h2>Prosimy spróbować ponownie później</h2>
          {(message || error?.message) && (
            <span className="text-muted mt-4">{message || error?.message}</span>
          )}
        </>
      )}
      {children}
    </div>
  )
}


export default withRouter(ErrorBoundary);
