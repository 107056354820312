import {TYPE_SET_TMP_FILE} from "app/redux/chats&notifications/chatsTypes";


export const actionSetNewTmpFile = (chatId, file) => ({
  type: TYPE_SET_TMP_FILE,
  payload: {
    chatId,
    file,
  },
});
