import React, {useEffect, useState} from 'react';
import {Alert} from "reactstrap";
import {useTranslation} from "react-i18next";

const getErrorMsg = (error, t) => {
  console.log("error", error)

  if (!error) return null;

  if (typeof error === 'string' || error?.i18n) return error;

  if (error?.response?.data?.errors?.length) {
    return Object
      .keys(error?.response?.data?.errors)
      .map(key => error?.response?.data?.errors?.[key]?.message)
      ?.join(', ');
  }

  if (error?.response?.data?.Errors?.length) {
    return error?.response?.data?.Errors
      .map(({Message}) => Message)
      ?.join(', ');
  }

  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.status) {
    switch (error?.response?.status) {
      case 404:
        return <span>Strona nie istnieje {'('}StatusCode: {error?.response?.status})</span>;//TODO: redirect to Err404
      case 403:
        return <span>Brak dostępu {'('}StatusCode: {error?.response?.status})</span>;//TODO: redirect to Err403
    }
  }

  if (error?.response?.data?.Message) return error?.response?.data?.Message;

  // DEFAULT:
  return error?.toString();
}

export const ApiErrors = ({error}) => {
  const {t} = useTranslation();
  const [errorMsg, setErrorMsg] = useState('');
  useEffect(() => {
    setErrorMsg(getErrorMsg(error))
  }, [error])
  if (!error) return null;
  return (
    <Alert color="danger">
      {errorMsg?.i18n ? t(errorMsg?.i18n) : errorMsg}
    </Alert>
  );
};
