import axios from "axios";
import {BASE_URL_API} from "app/config/env";
import usePaginatedQuery2 from 'app/hooks/usePaginatedQuery2';

const getChats = (key, page = 1, results = 15, search) => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/company-panel/chats`,
  params: {
    pageNumber: page,
    pageSize: results,
    search
  }
});

export const useChats = (onSuccess, results) => usePaginatedQuery2({
  queryKey: ['pc.chats2'],
  queryFn: getChats,
  config: {
    onSuccess,
    enabled: results != null,
  },
  passedResults: results,
});
