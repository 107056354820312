import React, {useRef, useState} from 'react';
import useDetectOutsideClick from "app/hooks/useDetectOutsideClick";

const INSIDE = true;
const OUTSIDE = false;

export const DetectOutsideClick = (
  {
    children,
    onClickInside = () => {
    },
    onClickOutside = () => {
    },
    className = ''
  }) => {

  const containerRef = useRef(null);
  const stateRef = useRef(OUTSIDE);

  const [state, setState] = useState(OUTSIDE);
  stateRef.current = state;

  const handleClickInside = () => {
    onClickInside(stateRef.current === INSIDE);
    setState(INSIDE);
  }

  const handleClickOutside = () => {
    onClickOutside(stateRef.current === OUTSIDE);
    setState(OUTSIDE);
  }

  useDetectOutsideClick(containerRef, handleClickOutside, handleClickInside, '.modal-content, .flatpickr-calendar');

  return (
    <div ref={containerRef} className={`bg-transparent ${className}`}>
      {children}
    </div>
  );
};
