import {COMPANY_PANEL} from "app/config/urls/prefixes";
const prefix = `${COMPANY_PANEL}/orders`;

// pc = panel.company

export const orderRoutes = {
  'pc.order':     `${prefix}`,
  'pc.order.add': `${prefix}/add`,
  'pc.order.show': (id=':orderId') => `${prefix}/${id}`,
  'pc.order.edit': (id=':orderId') => `${prefix}/${id}/edit`,
};
