import React, { useEffect, useRef, useState } from 'react';
import { useSelectChatsSorted } from "app/redux/chats&notifications/selectors/selectChatsSorted";
import { useDispatch } from "react-redux";
import { actionAddNewMessage } from "app/redux/chats&notifications/chatActions/actionAddNewMessage";
import { useMessagesHub, useMessagesHubReceiveMessage } from "app/hooks/signalR/useMessagesHub";
import { Chat } from "views/pages/companyPanel/conversations/View_Conversation/components/chats/Chat";
import { useChats } from "app/crud/global/messages/getChats";
import { actionSetChats } from "app/redux/chats&notifications/chatActions/actionSetChats";
import { useParams } from "react-router-dom";
import Spinner from "components/@vuexy/spinner/Loading-spinner";
import styled from 'styled-components';
import { useFilters } from 'app/hooks/useFilters';
import { useChatsInfo } from 'app/crud/global/messages/getInfo';
import { useTranslation } from "react-i18next";
import { useChatContext } from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";
import { useSelectPageSize } from "app/redux/chats&notifications/selectors/selectPageSize";
import { actionSetPagination } from "app/redux/chats&notifications/chatActions/actionSetPagination";


const StyledChatsWrapper = styled.div`
    border-radius: 0.5rem 0 0 0.5rem !important;
`;

export const ChatList = ({ onChatSelect }) => {
  const { t } = useTranslation()
  const {chatsSorted} = useSelectChatsSorted();
  const pageSize = useSelectPageSize()
  const chatsIdRef = useRef(null);
  chatsIdRef.current = chatsSorted;
  const [messagesConnection] = useMessagesHub();
  const dispatch = useDispatch();

  const handleLoadNewMessages = (props) => {
    const { data, pagedData } = props.data
    dispatch(actionSetChats(data, pagedData));
  }
  // const [currentFilters, nextFilters, filterActions] = useFilters();
  const { refetch, status, pagination } = useChats(handleLoadNewMessages, pageSize);

  const handleGetNewMessage = (message) => {
    dispatch(actionAddNewMessage(message.chatConversationId, message));
    //TODO: it can destroy entire app :)
    refetch();
    if (chatsIdRef.current.indexOf(message.chatConversationId + '') === -1) {
      // noinspection JSIgnoredPromiseFromCall
      refetch();
    }
  }
  useMessagesHubReceiveMessage(messagesConnection, handleGetNewMessage);

  const handleLoadMoreMessages = () => {
    dispatch(actionSetPagination)
  }

  return (
    <>
      {/* <FilterBox filters={nextFilters} {...filterActions} /> */}
      {!chatsSorted.length ? (
        status === 'success' ? (
          <div className="small py-5 text-center text-lowercase">({t('message.noData')})</div>
        ) : (
          <div className="position-relative py-3">
            <Spinner />
          </div>
        )
      ) : (
        <>
          {chatsSorted.map((chatId) => (
            <Chat
              onClick={onChatSelect}
              chatId={chatId}
              key={chatId}
            />
          ))}
          {pagination.totalResults > pagination.results && (
            <button className="btn btn-sm bg-theme-secondary mx-auto mt-1 d-block" onClick={handleLoadMoreMessages}>
              {t('generic.viewMore')}
            </button>
          )}
        </>
      )}
    </>
  )
}

export const Chats = () => {
  const activeChatId = useChatContext()
  const {chatId} = useParams();
  const {refetch} = useChatsInfo();
  useEffect(() => {
    refetch();
  }, [chatId]);


  return (
    <StyledChatsWrapper className={`${activeChatId == null ? 'd-flex' : 'd-none d-sm-flex'} col-12 col-sm-4 mh-100 p-0 mb-0 border-right`}>
      <StyledChatsWrapper className="card card-custom flex-grow-1 m-0 shadow-none">
        {/* <Search /> */}
        <div className="card-body pb-25 pt-5 px-50 overflow-auto position-relative">
          <ChatList />
        </div>
      </StyledChatsWrapper>
    </StyledChatsWrapper>
  );
};
