import {COMPANY_PANEL} from "app/config/urls/prefixes";
import {driversRoutes} from "app/config/urls/companyPanel/employees/drivers";
const prefix = `${COMPANY_PANEL}/employees`;

//pc = panel.company

export const employeeRoutes = {
  'pc.employee': `${prefix}`,
  'pc.employee.add': `${prefix}/add`,
  'pc.employee.show': (id=':employeeId') => `${prefix}/${id}`,
  'pc.employee.edit': (id=':employeeId') => `${prefix}/${id}/edit`,
  //
  ...driversRoutes,
}
