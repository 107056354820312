const getBase64 = async (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      console.log(reader.result.split(','))
      resolve(reader.result.split(',')[1])
    };
    reader.onerror = (error) => reject(error);
  })
}

export default getBase64
