import {COMPANY_PANEL} from "app/config/urls/prefixes";
import React from "react";

const prefix = `${COMPANY_PANEL}/available-vehicles`;

// pc = panel.company

export const availableVehiclesRoutes = {
  "pc.availableVehicles": `${prefix}`,
};
