import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner,} from "reactstrap";
import styled from "styled-components";
import {useGetAvailableLanguages} from "app/crud/global/translations/getAvailableLanguages";
import {Query2Provider} from "app/context/Query2Provider";
import useObjectState from "app/hooks/useObjectState";
import {setDefaultLanguage} from "app/crud/global/translations/setDefaultLanguage";
import {useDispatch} from "react-redux";
import {useSelectorAuth} from "app/redux/actiontypes/auth/authSelectors";
import {actionSetLanguage} from "app/redux/actiontypes/auth/loginActions";
import {ChevronDown} from "react-feather";

const SwitcherFlag = styled.span`
  height: 1.2em;
  width: 1.6em;
  background-size: cover !important;
  box-shadow: #0004 0 0 0.2em 0px;
`;

const LanguageSwitcher = (
  {
    className = '',
    onLanguageChange = () => {
    }
  }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [{isLoading, isSuccess}, setState] = useObjectState({isLoading: false, isSuccess: false});
  const {token, language} = useSelectorAuth();
  const [{languageId, languageCode}, setLanguage] = useState(language);
  const dispatch = useDispatch();

  const handleChangeLanguage = (languageId, code) => async () => {
    if (token && code !== 'debug') {
      if (!isLoading) {
        setState({isLoading: true, isSuccess: false, error: null});
        try {
          await setDefaultLanguage(languageId);
          setState({isLoading: false, isSuccess: true, error: null});
          dispatch(actionSetLanguage(languageId, code));
          setLanguage({languageId, languageCode: code})
          onLanguageChange({languageId, code});
        } catch (err) {
          console.log(languageId, code);
          setState({isLoading: false, isSuccess: false});
        }
      }
    } else {
      setLanguage({languageId, languageCode: code});
      dispatch(actionSetLanguage(languageId, code));
      onLanguageChange({languageId, code});
    }
  }

  const query = useGetAvailableLanguages();

  return (
    <Dropdown tag="li" isOpen={dropdownOpen} value={language} toggle={toggle}
              className={`dropdown-language nav-item ${className}`}>
      <Query2Provider {...query} loadingComponent={<Spinner size="sm"/>} errorComponent={null}>
        {languages => (
          <>
            <DropdownToggle color="link" className="px-1 py-0">
              <div>
                {isLoading ? <Spinner size="sm"/> :
                  <div className="d-flex flex-row">
                    <SwitcherFlag className={`flag-icon flag-icon-${languageCode?.toLowerCase() || 'pl'}`}/>
                    <ChevronDown size={17}/>
                  </div>
                }
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              {languages.data.map(({id, name, code}) => (
                <DropdownItem
                  key={id}
                  disabled={id === languageId}
                  onClick={handleChangeLanguage(id, code)}
                  className={`w-100 ${id === language?.languageId ? 'text-primary font-weight-bold' : ''}`}
                >
                  <span className={`flag-icon flag-icon-${code} mr-1`}/>
                  {name}
                </DropdownItem>
              ))}
              {/* DEBUG LANGUAGE */}
              {process?.env?.NODE_ENV === 'development' && (
                <DropdownItem
                  disabled={-1 === languageId}
                  onClick={handleChangeLanguage(-1, 'debug')}
                  className={`w-100 ${-1 === language?.languageId ? 'text-primary font-weight-bold' : ''}`}
                >
                  Debug
                </DropdownItem>
              )}
            </DropdownMenu>
          </>
        )}
      </Query2Provider>
    </Dropdown>
  );
};

export default LanguageSwitcher;
