export const createChatEmployee = ({
  id,
  firstName,
  lastName,
  contactEmail,
  internalStatus,
  photoUrl,
  company
}) => ({
  id,
  name: `${firstName ?? ''} ${lastName ?? ''} ${!firstName && !lastName ? (contactEmail ?? '') : ''}`,
  contactEmail,
  internalStatus,
  photoUrl,
  company
});
