import {createPlaceCargo} from "app/redux/editOrder/utils/createPlaceCargo";

export const setAllCargos = (state, {orderCargos: passedOrderCargos}) => {
  let orderCargos = {};
  let orderCargosKeys = [];

  passedOrderCargos.forEach(cargo => {
    if(!cargo?.id) return;
    orderCargos[cargo.id] = createPlaceCargo(cargo);
    orderCargosKeys.push(cargo.id);
  });

  return {
    ...state,
    orderCargos,
    orderCargosKeys,
  };
}
