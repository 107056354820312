import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {useState, useRef} from 'react';
import {Badge, Dropdown, DropdownItem as DropdownItemBootstrap, DropdownMenu, DropdownToggle} from "reactstrap";
import * as Icon from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar";
import {Link, useLocation} from "react-router-dom";
import {route} from "app/config/urls/routes";
import Avatar from "components/@vuexy/avatar/AvatarComponent";
import {ChatList} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/Chats";
import {useChatsInfo} from "app/crud/global/messages/getInfo";
import {useMessagesHub, useMessagesHubReceiveMessage} from 'app/hooks/signalR/useMessagesHub';
import messageSound from '@assets/mp3/message.wav';
import useMoment from "app/hooks/useMoment";
import { useTranslation } from "react-i18next";
import { actionOpenChatbox } from "app/redux/chats&notifications/chatActions/actionChatbox";


const Chat = ({id, toEmployee, toEmployeeId, fromEmployee, loggedEmployeeId, lastMessage, unreadCount}) => {
  const moment = useMoment()
  const employee = toEmployeeId === loggedEmployeeId ? fromEmployee : toEmployee;
  return (
    <Link to={route['pc.conversations.messages'](id)} className="position-relative d-flex pl-25 align-items-center mb-50" key={id}>
      {!!unreadCount && (
        <div className="position-absolute top-0 left-0">
          <div className="badge badge-xs badge-pill badge-primary ml-auto">{unreadCount}</div>
        </div>
      )}
      <Avatar img={employee?.photoUrl} />
      <div className="pl-50">
        <div className={`text-dark ${unreadCount ? 'font-weight-bolder' : ''}`}>{employee?.firstName ?? ''} {employee?.lastName ?? ''}</div>
        {/*{employee?.languages && !!employee?.languages?.length && (*/}
        {/*  <div>*/}
        {/*    {employee?.languages?.map(({id, countryCodeA2}) => <FlagIcon className="font-small-2 mr-25" key={id} code={countryCodeA2} />)}*/}
        {/*  </div>*/}
        {/*)}*/}
        {lastMessage?.message && <span className="text-muted">{lastMessage?.message}</span>}
      </div>
      <div className="ml-auto pr-50 small text-muted">
        {lastMessage?.createdAt && moment(lastMessage?.createdAt).fromNow(true)}
      </div>
      {/*{firstName, lastName, languages}*/}
    </Link>
  )
}

const notificationSound = new Audio(messageSound);

export const Chats = () => {
  const { t } = useTranslation()
  const [isOpenDropdown, setIsOpen] = useState(false);
  const {data, refetch} = useChatsInfo();
  const [messagesConnection] = useMessagesHub();

  const handleGetNewMessage = (message) => {
    if (!message?.isLoggedEmployeeSender) {
      notificationSound.play();
      refetch();
    }
  }

  useMessagesHubReceiveMessage(messagesConnection, handleGetNewMessage);

  const handleToggleIsOpenDropdown = () => setIsOpen(!isOpenDropdown);

  return (
    <>
      <Dropdown
        isOpen={isOpenDropdown}
        toggle={handleToggleIsOpenDropdown}
        tag="li"
        className="dropdown-notification nav-item d-lg-none"
      >
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Mail size={21} />
          {!!data?.hasUnreadConversations && (
            <Badge pill color="primary" className="badge-up">
              {` ${data.unreadConversations} `}
            </Badge>
          )}
          {/* {!!conversations?.unreadCount && (
            <Badge pill color="primary" className="badge-up">
              {` ${conversations.unreadCount} `}
            </Badge>
          )} */}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <>
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <span className="notification-title">{t('message.list')}</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative pt-50 pb-75"
              options={{
                wheelPropagation: false
              }}
            >
              {isOpenDropdown && <ChatList />}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItemBootstrap tag={Link} to={route['pc.conversations']} className="p-1 text-center">
                <span className="align-middle">{t('generic.viewAll')}</span>
              </DropdownItemBootstrap>
            </li>
          </>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
