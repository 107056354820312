import {createChat} from "app/redux/chats&notifications/utlis/createChat";
import {errorHandler} from "app/utility/errorHandler";
import {reducerUpdateChat} from "app/redux/chats&notifications/reducers/chat/reducerUpdateChat";

export const reducerCreateChat = (state, { chat }) => {

  // ERROR chat.id not exists
  if(!chat?.id) {
    errorHandler({
      expected: 'payload.hat.id to be set',
      got: chat?.id,
      json: chat,
      file: 'reducerCreateChat.js',
      details: 'cannot crate chat without chatId',
    });
    return state;
  }


  if(state?.chats?.[chat.id]) { // chat already exists
    return reducerUpdateChat(state, {chat});
  } else {
    return {
      ...state,
      chats: {
        ...state.chats,
        [chat?.id]: createChat(chat),
      }
    }

  }
}
