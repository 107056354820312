import axios from "axios";
import {BASE_URL_API} from "app/config/env";

export const setDefaultLanguage = (languageId) => axios({
  method: 'POST',
  url: `${BASE_URL_API}/v1/auth/set-default-language`,
  data: {
    languageId,
  }
});
