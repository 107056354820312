import {COMPANY_PANEL} from "app/config/urls/prefixes";
import React from "react";

const prefix = `${COMPANY_PANEL}/contractor-groups`;

// pc = panel.company

export const contractorGroupRoutes = {
  'pc.contractorGroup': `${prefix}`,
  'pc.contractorGroup.add': `${prefix}/add`,
  'pc.contractorGroup.show': (id = ':contractorGroupId') => `${prefix}/${id}`,
  'pc.contractorGroup.edit': (id = ':contractorGroupId') => `${prefix}/${id}/edit`,
}
