
import React from 'react';
import PropTypes from 'prop-types';
import PublicFooter from "./includes/footer/PublicFooter";
import PublicNavbar from "./includes/navbar/PublicNavbar";
import styled from 'styled-components';

const PublicWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PublicLayout = ({ children, fullWidth }) => {
  return (
    <PublicWrapper>
      <PublicNavbar />
      <div className={`flex-grow-1 ${fullWidth ? "" : "container bg-white my-3 px-3 py-5"}`}>
        {children}
      </div>
      <PublicFooter />
    </PublicWrapper>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
}

export default PublicLayout;
