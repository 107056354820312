import React from 'react'
import { useModal } from 'app/hooks/useModal';
import Picker from 'emoji-picker-react'
import { UncontrolledTooltip } from 'reactstrap';
import { DetectOutsideClick } from 'components/events/DetectOutsideClick';
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const groupNames = (t) => ({
  smileys_people: t('message.emojiEmotion'),  // emocje,
  animals_nature: t('message.emojiNature'),   // zwierzęta i natura,
  food_drink: t('message.emojiFood'),         // jedzenie i napoje,
  travel_places: t('message.emojiTravel'),    // podróże i miejsca,
  activities: t('message.emojiActivity'),     // zajęcia,
  objects: t('message.emojiObject'),          // obiekty,
  symbols: t('message.emojiSymbol'),          // symbole,
  flags: t('message.emojiFlag'),              // flagi,
  recently_used: t('message.emojiRecent'),    // ostatnio używane,
})

const PickerContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-80%, -100%);

  .content-wrapper {
    padding: initial !important;
    margin-top: initial !important;
    min-height: initial !important;
  }

  .emoji-picker-react {
    background: inherit;
    box-shadow: none;
    border-color: var(--bg-theme-secondary);
    background: var(--bg-theme);
  }
  .emoji-group:before {
    background: var(--bg-theme);
  }
  body.dark-layout & .emoji-categories {
    filter: invert(1);
  }
  .emoji-search {
    background: var(--bg-theme-secondary) !important;
    border-color: var(--bg-theme-muted) !important;
    color: var(--text-theme-secondary) !important;
  }
`

export const EmojiPicker = ({onSelect, ...props}) => {
  const { t } = useTranslation()
  const [isOpenModal, openModal, closeModal] = useModal();

  return (
    <div className="position-relative">
      <UncontrolledTooltip target='emojiPopover'>
        {t('message.insertEmoji')}
      </UncontrolledTooltip>
      <button
        className="btn btn-outline-none m-0 px-1 py-50"
        id="emojiPopover"
        onClick={isOpenModal ? closeModal : openModal}
      >
        <h4 className='mb-0'>
          {String.fromCodePoint(0x1f60a)}
        </h4>
      </button>
      {isOpenModal && (
        <DetectOutsideClick
          onClickOutside={closeModal}
          onClickInside={() => { }}
        >
          <PickerContainer>
            <Picker
              onEmojiClick={onSelect}
              pickerStyle={{
                width: '300px',
                height: '400px',
              }}
              groupNames={groupNames(t)}
              native
              showPreview={false}
              disableSkinTonePicker
              {...props}
            />
          </PickerContainer>
        </DetectOutsideClick>
      )}
    </div>
  )
}
