import React from 'react';
import {useSelectChatInfo} from "app/redux/chats&notifications/selectors/selectChatInfo";
import {route} from "app/config/urls/routes";
import Avatar from "components/@vuexy/avatar/AvatarComponent";
import styled from "styled-components";
import useMoment from "app/hooks/useMoment";
import {useChatContext} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";
import {useHistory} from "react-router";

const ChatContainer = styled.a`
  .avatar {
    margin: 0;
  }
`

export const Chat = ({ chatId, onClick }) => {
  const activeChatId = useChatContext()
  const {chat} = useSelectChatInfo(chatId);
  const moment = useMoment()
  const history = useHistory()
  const handleClick = onClick ?? (
    (chatId) => history.replace(route['pc.conversations.messages'](chatId))
  )

  if (!chat)
    return null

  return (
    <ChatContainer
      onClick={() => handleClick(chatId)}
      className={`position-relative d-flex align-items-center p-50 mb-25 rounded-lg bg-theme-secondary-hover ${activeChatId == chatId ? 'bg-theme-secondary ' : ''}`}
    >
      <Avatar img={chat.employee?.photoUrl} />
      <div className="ml-75 flex-grow-1 overflow-hidden">
        <div className={`text-dark overflow-hidden text-overflow-ellipsis text-nowrap ${chat.unreadCount ? 'font-weight-bolder' : ''}`}>{chat.employee?.name ?? '.'}</div>
        <div className="d-flex">
          {chat.lastMessage?.message && (
            <span className="text-muted small mr-1 d-block text-nowrap overflow-hidden text-overflow-ellipsis">
              {chat.lastMessage?.message}
            </span>
          )}
          <div className="text-muted text-nowrap ml-auto small">

            {chat.lastMessage?.createdAt && moment(chat.lastMessage?.createdAt).fromNow(true)}
          </div>
        </div>
      </div>
      {!!chat.unreadCount && activeChatId !== chatId && (
        <div className="position-absolute top-0 left-0">
          <div className="badge badge-xs badge-pill badge-primary ml-auto">{chat.unreadCount}</div>
        </div>
      )}
      {/*{firstName, lastName, languages}*/}
    </ChatContainer>
  );
};
