import {route} from "app/config/urls/routes";
import * as I from 'react-feather';

export const N_ORDER_CREATED = 1;
export const N_ORDER_SOLD = 2;
export const N_ORDER_BY_OWN_TRANSPORT = 3;
export const N_ORDER_CANCELED = 4;

export const N_COMPANY_REGISTER = 90;

export const NOTIFICATION_TYPES = {
  // Order
  [ N_ORDER_CREATED ]:          { Icon: I.PlusSquare, color: 'primary', redirectTo: (i1)=>route['pc.order.show'](i1)},
  [ N_ORDER_SOLD ]:             { Icon: I.DollarSign, color: 'success', redirectTo: (i1)=>route['pc.order.show'](i1)},
  [ N_ORDER_BY_OWN_TRANSPORT ]: { Icon: I.Truck,      color: 'primary', redirectTo: (i1)=>route['pc.order.show'](i1)},
  [ N_ORDER_CANCELED ]:         { Icon: I.X,          color: 'danger',  redirectTo: (i1)=>route['pc.order.show'](i1)},
  // Company
  [ N_COMPANY_REGISTER ]:       { Icon: I.Home,       color: 'info',    redirectTo: ()=>route['pc.myCompany']},
}
