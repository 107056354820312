import {
  LOGIN_WITH_EMAIL,
  LOGOUT,
  SET_LANGUAGE,
} from "app/redux/actiontypes/auth/types";
import { TYPE_CLEAR_CHATS } from "app/redux/chats&notifications/chatsTypes";

export const loginWithEmail = (
  userId,
  token,
  roles,
  userName,
  email,
  profilePhotoUrl,
  languageId,
  languageCode,
  companyId,
  hasLimitedAccess,
  accesses,
) => ({
  type: LOGIN_WITH_EMAIL,
  payload: {
    userId,
    token,
    userRoles: roles,
    userName,
    email,
    profilePhotoUrl,
    language: {
      languageId,
      languageCode,
    },
    companyId,
    hasLimitedAccess,
    accesses,
  },
});

export const logout = () => ({
  type: LOGOUT,
});

export const clearChats = () => ({
  type: TYPE_CLEAR_CHATS,
});

export const actionSetLanguage = (languageId, languageCode) => ({
  type: SET_LANGUAGE,
  payload: {
    languageId,
    languageCode,
  },
});
