import {orderPlacesError} from "app/redux/editOrder/reducers/_error";
import {ORDER_PLACES_ERROR__MISSING_CARGO} from "app/redux/editOrder/actionTypes";

export const deleteSingleCargo = (state, {cargoId}) => {

  if(!state.orderCargos[cargoId]) {
    return orderPlacesError(state, { errCode: ORDER_PLACES_ERROR__MISSING_CARGO })
  }

  //TODO: add key validation (or not?)
  const { [cargoId]: deletedCargo, ...orderCargos } = state.orderCargos;

  return {
    ...state,
    orderCargos,
    orderCargosKeys: state.orderCargosKeys.filter(id=>id!==cargoId),
  };
}
