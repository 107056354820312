import React from 'react';
import {Image} from "react-feather";
import {useDropzone} from "react-dropzone";
import {Modal, ModalBody, ModalHeader, UncontrolledTooltip} from 'reactstrap';
import {useModal} from 'app/hooks/useModal';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {actionSetNewTmpFile} from "app/redux/chats&notifications/chatActions/actionSetNewTmpFile";
import {useChatContext} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";
import {toast} from "react-toastify";

// const getBase64 = async (file) => {
//   const reader = new FileReader();
//   reader.readAsDataURL(file);
//
//   return new Promise((resolve, reject) => {
//     reader.onload = () => {
//       console.log(reader.result.split(','))
//       resolve(reader.result.split(',')[1])
//     };
//     reader.onerror = (error) => reject(error);
//   })
// }
//
// const FormSendFile = ({chatId, closeModal, refetch = () => { }}) => {
//   const { t } = useTranslation()
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [error, setError] = useState(null);
//   const {getRootProps, getInputProps, acceptedFiles} = useDropzone({multiple: false});
//   console.log(acceptedFiles)
//   const path = acceptedFiles?.[0]?.path;
//
//   const [, {sendConversationFile}] = useMessagesHub();
//
//   const handleSuccess = () => {
//     refetch();
//     closeModal();
//   }
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!isSubmitting) {
//       setError(acceptedFiles?.length ? null : {type: 'validation', message: t('generic.requiredField')});
//       if (acceptedFiles?.length) {
//         setIsSubmitting(true);
//         const data = {
//           "file": await getBase64(acceptedFiles?.[0]),
//           "fileName": acceptedFiles?.[0]?.name
//         }
//         try {
//           await sendConversationFile(chatId, data);
//           //await sendFile(chatId, data);
//           handleSuccess();
//         } catch (error) {
//           setIsSubmitting(false)
//           console.log("error: ", error)
//           setError({type: 'api', message: error?.response?.data?.Message});
//         }
//       }
//     }
//   }
//
//   return (
//     <form onSubmit={handleSubmit}>
//       <div className="form-group">
//         <div tabIndex="0" className="dropzone cursor-pointer" {...getRootProps()}>
//           <input name="file" {...getInputProps()} />
//           <div className="mx-1">
//             <div>
//               {path ? `"${path}"` : t('generic.fileUploadPrompt')}
//             </div>
//           </div>
//         </div>
//         {error && (
//           error?.type === 'validation' ? (
//             <div className="small text-danger">
//               {error?.message}
//             </div>
//           ) : (
//             <div className="alert alert-danger">
//               {error?.message}
//             </div>
//           )
//         )}
//       </div>
//       <div className="text-right">
//         <span className="btn btn-outline-secondary mr-1" onClick={closeModal}>
//           {t('generic.cancel')}
//         </span>
//         <Button
//           type="submit"
//           color="primary"
//           disabled={!!isSubmitting || !acceptedFiles?.length}
//           size="md"
//         >
//           {isSubmitting ? (
//             <Spinner color="white" size="sm" className="mr-50" />
//           ) : (
//             <Upload size={14} className="mr-50" />
//           )}
//           <span>{t('generic.send')}</span>
//         </Button>
//       </div>
//     </form>
//   );
// };


export const MessageFileInput = () => {
  const {t} = useTranslation()
  const [isModalOpen, openModal, closeModal] = useModal();
  const dispatch = useDispatch();
  const activeChatId = useChatContext()

  const onDropAccepted = (files) => {
    if (!files?.[0]) return
    closeModal()
    dispatch(actionSetNewTmpFile(activeChatId, files[0]));
  } 
  const onDropRejected = () => {
    toast.error(t('message.attachmentError'))
  }

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple: false,
    onDropAccepted,
    onDropRejected,
    accept: ['image/png', 'image/jpeg', 'image/svg+xml', 'video/mp4', 'video/quicktime', 'video/mpeg', 'application/pdf'],
    noDragEventsBubbling: true,
  });

  return (
    <>
      <UncontrolledTooltip target="messageImageAddBtn">
        {t('generic.attachFile')}
      </UncontrolledTooltip>
      <div className="btn-slink p-50 btn btn-link px-1" id="messageImageAddBtn" onClick={openModal}>
        <Image size={20}/>
      </div>
      <Modal
        isOpen={isModalOpen}
        toggle={closeModal}
      >
        <ModalHeader toggle={closeModal}>
          {t('generic.sendFile')}
        </ModalHeader>
        <ModalBody>
          <div tabIndex="0" className="dropzone cursor-pointer" {...getRootProps()}>
            <input name="file" {...getInputProps()} />
            <div className="mx-1 text-center">
              {t('generic.fileUploadPrompt')}
              <div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
