import {TYPE_SET_TMP_MESSAGE} from "app/redux/chats&notifications/chatsTypes";


export const actionSetNewTmpMessage = (chatId, newMessage) => ({
  type: TYPE_SET_TMP_MESSAGE,
  payload: {
    chatId,
    newMessage,
  },
});
