import {TYPE_ADD_NEW_MESSAGE} from "app/redux/chats&notifications/chatsTypes";


export const actionAddNewMessage = (chatId, message) => ({
  type: TYPE_ADD_NEW_MESSAGE,
  payload: {
    chatId,
    message,
  },
});
