import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import { dispatchSetMenuData } from "app/redux/reducers/notificationsAndMessages/actionTypes";
import {useMenuData} from "app/crud/global/getMenuData";
import {useSelectorAuth} from "app/redux/actiontypes/auth/authSelectors";

const GetMenuData = () => {
  const {token} = useSelectorAuth();
  const {
    data: {
      countUnreadChats,
      countUnreadNotifications,
    },
    status,
  } = useMenuData(!!token);
  const dispatch = useDispatch();

  useEffect(() => {
    if(status === 'success') {
      dispatch(dispatchSetMenuData(countUnreadChats, countUnreadNotifications));
    }
  }, [countUnreadChats, countUnreadNotifications]);

  return null;
}


// separated files to prevent re-rendering
export const NotificationProvider = ({ children }) => (
  <>
    <GetMenuData/>
    {children}
  </>
);
