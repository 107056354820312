import {useQuery} from "react-query";
import {useHistory, useLocation} from "react-router-dom";
import qs from "qs";

const DEFAULT_PAGE = 1;
const DEFAULT_RESULTS = 30;//page size
const MAX_RESULTS = 100;

const usePaginatedQuery2 = ({
                              queryKey,
                              queryFn,
                              config,
                              passedPage,
                              passedResults,
                              defaultData = [],
                              getData = (queryData) => (queryData?.data ?? {}),
                            }) => {

  const history = useHistory();
  const {search} = useLocation();
  const {
    page: pageParam,
    results: resultsParam,
    search: searchQuery,
    ...params
  } = qs.parse(search, {ignoreQueryPrefix: true});

  const newPage = Math.max(Math.abs(parseInt(pageParam) || passedPage || DEFAULT_PAGE), 1);
  const newResults = Math.min(50, Math.max(Math.abs(passedResults || parseInt(resultsParam) || DEFAULT_RESULTS), 1), MAX_RESULTS);

  const newParams = {
    page: newPage,
    results: newResults,
    ...params,//TODO: lock rest params??????? or allow only some of them
  }
  const {
    data: queryData,
    error,
    isFetching,
    status,
    refetch,
    isIdle,
    isStale,
    failureCount,
    isLoading,
  } = useQuery(
    [...queryKey, newPage, newResults, searchQuery, params],
    () => queryFn(...queryKey, newPage, newResults, searchQuery, params),
    {
      ...config,
      keepPreviousData: true,
    },
  );

  const show = true;//TODO: add error validation

  const setPage = (pageNo) => (ev) => {
    ev.preventDefault();
    const search = '?' + qs.stringify({
      ...newParams,
      page: pageNo,
    })
    history.push({search})
  }

  const getUrl = (routeBase = '', page = 1) => `${routeBase}?${qs.stringify({...newParams, page})}`;

  const loadingInfo = {status, error, isFetching, isIdle};
  const {data = defaultData, pagedData = {}} = getData(queryData) ?? {data: defaultData, pagedData: {}};
  const totalResults = pagedData?.resultsCount ?? 0;
  const totalPages = pagedData?.pages ?? 0;

  return {
    data,
    error,
    isFetching,
    status,
    refetch,
    loadingInfo,
    isLoading,
    pagination: {
      setPage,
      page: newPage,
      results: newResults,
      show,
      getUrl,
      totalPages,
      totalResults,
    },
    isStale,
    failureCount,
  }
}

export default usePaginatedQuery2;
