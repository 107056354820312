import React, {useContext, useEffect, useMemo, useRef} from "react";
import {useSelectorAuth} from "app/redux/actiontypes/auth/authSelectors";
import {messagesHub} from "app/context/signalR/hubs/messagesHub";
import {notificationHub} from "app/context/signalR/hubs/notificationHub";


const SignalRContext = React.createContext({
  messagesHub,
  notificationHub,
});

export const SignalRProvider = ({children}) => {

  const connectionsRef = useRef({});
  const tokenRef = useRef(null);

  // -- TOKEN --
  const {token} = useSelectorAuth();
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  // ----- create connection hubs -----
  useMemo(() => {// as componentWillMount
    // -- messages Hub --
    console.log("Signal Provider Mounted")
    try {
      connectionsRef.current.messagesHub = messagesHub(tokenRef, token);
      connectionsRef.current.notificationHub = notificationHub(tokenRef, token);
    } catch (e) {
      console.err(e);
    }
  }, []);

  // ----- destroy connection hubs -----
  useEffect(() => () => {// as componentWillUnmount
    try {
      console.log("Signal Provider Unmounted")
      connectionsRef.current.messagesHub.stop().then(() => {
        //console.log('messageHub connection stopped')
      });
      connectionsRef.current.notificationHub.stop().then(() => {
        //console.log('notificationHub connection stopped')
      });
    } catch (e) {
      //console.log('err,', 2);
    }
  }, []);

  return (
    <SignalRContext.Provider value={{connectionsRef}}>
      {children}
    </SignalRContext.Provider>
  );
}

export const useSignalRConnections = () => {
  const {connectionsRef} = useContext(SignalRContext);
  return connectionsRef;
}
