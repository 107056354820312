import {useSignalRConnections} from "app/context/signalR/SignalRProvider";
import {useCallback, useEffect} from "react";
import {useSignalREvent} from "app/hooks/signalR/useSignalrEvent";
import {HUB_NOTIFICATIONS_RECEIVE_NOTIFICATION} from "app/context/signalR/hubs/notificationHub";
import { useSelector } from "react-redux";


export const useNotificationHub = () => {
  const hasRole = useSelector(({auth}) => !!auth.login?.userRoles?.length);

  // check if connection exists
  const connections = useSignalRConnections();
  if (hasRole && !connections?.current?.notificationHub) {
    throw new Error('Missing SignalRProvider');
  }
  useEffect(() => {
    if (!hasRole) return
    if (connections.current.notificationHub.connectionState === 'Disconnected') {
      connections.current.notificationHub.start().then(() => {
        console.log('connection started')
      });
    }
  }, [hasRole]);

  return [
    connections.current.notificationHub, // connection
  ];
}

// getMessage observer
export const useNotificationHubReceiveMessage = (notificationConnection, callback) => {
  return useSignalREvent(notificationConnection, HUB_NOTIFICATIONS_RECEIVE_NOTIFICATION, callback);
}
