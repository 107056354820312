import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/my-exchanges`;

//pc = panel.company

export const myExchangesRoutes = {
  'pc.myExchange': `${prefix}`,
  'pc.myExchange.show': (id = ':exchangeId') => `${prefix}/${id}`,
  'pc.myExchange.add': `${prefix}/add`,
  'pc.myExchange.edit': (id = ':exchangeId') => `${prefix}/${id}/edit`,
  'pc.myExchange.notice': (exchangeId = ':exchangeId', noticeId = ':noticeId') => `${prefix}/${exchangeId}/notice/${noticeId}`,
}
