import React, {lazy, useEffect} from "react"
import {Route, BrowserRouter, useLocation} from "react-router-dom"
import {ViewErr404} from "views/pages/error/View_404";
import {authRoutes} from "views/pages/authentication/Routes/Routes_Auth";
import {route} from "app/config/urls/routes";
import {AppSwitch} from "app/router/components/AppSwitch";
import "flatpickr/dist/themes/light.css";
import {PublicRoute} from "app/router/components/PublicRoute";
import {BASE_URL} from "app/config/env";
import {sharedRoutes} from "views/pages/shared/Routes/Routes_Shared";

// Route-based code splitting
const CompanyPanelRoutes = lazy(() => import('app/router/routes/RootRoutes_CompanyPanel'));
const PublicRoutes = lazy(() => import('views/pages/public/Routes/Routes_Public'));

const AppRouter = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    console.log(pathname)
    if(pathname.includes('company-panel')){
      document.documentElement.classList.add('hide-messenger');
    } else {
      document.documentElement.classList.remove('hide-messenger');
    }
  }, [pathname])
  return (
    // Set the directory path if you are deploying in sub-folder
    <AppSwitch>

      {/* PUBLIC ROUTES: */}
      <Route
        exact
        path={[
          route['/'],
          route['public.euProject'],
          route['public.privacyPolicy'],
          route['public.regulations'],
          route['public.pricing'],
          route['public.faq'],
          route['public.contact'],
          route['public.app'],
          route['public.company'],
          route['public.company.show'](),
          route['public.poster'],
          route['public.poster.show'](),
          route['public.test'],
          route['public.video'],
          route['public.blog'],
          route['public.blog.show'](),
        ]}
        component={PublicRoutes}
      />

      {/* COMPANY PANEL: */}
      <Route
        path={route['pc']}
        component={CompanyPanelRoutes}
      />
      <Route
        exact
        path={`${BASE_URL}/share/order/:keyId/:orderId`}
        component={null}
      />

      {/* AUTH: */}
      {authRoutes}

      {/* SHARED: */}
      {sharedRoutes}

      {/* 404: */}
      <PublicRoute component={ViewErr404} />
    </AppSwitch>
  )
}

export default AppRouter
