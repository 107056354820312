import React, {useState} from 'react'
import {useSelectorAuth} from 'app/redux/actiontypes/auth/authSelectors';
import {File} from 'react-feather';
import {Spinner} from 'reactstrap';
import {getFileNewTab} from 'app/crud/companyPanel/order/__OLD__files/openFileNewTab';
import { useTranslation } from "react-i18next";

export const FileMessage = ({message, fileUrl, messageType, messageId, id, isMine}) => {
  const { t } = useTranslation()
  const {token} = useSelectorAuth();
  const urlTab = fileUrl?.split('/')
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const handleDownload = async (e) => {
    setIsFileDownloading(true);
    e.preventDefault();
    try {
      await getFileNewTab(t)(token, fileUrl);
      setIsFileDownloading(false);
    } catch (e) {
      console.error('error', e);
    }
  }

  return (
    <div
      onClick={handleDownload}
      className="cursor-pointer font-weight-bold"
    >
      {isFileDownloading && isMine && <Spinner size="sm" className="mr-50" />}
      <File size={14} className="mr-50" />
      {message || urlTab[urlTab.length - 1]}
      {isFileDownloading && !isMine && <Spinner size="sm" className="ml-50" />}
    </div>
  )
}
