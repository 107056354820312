import {COMPANY_PANEL} from "app/config/urls/prefixes";
import React from "react";

const prefix = `${COMPANY_PANEL}/carriers`;

// pc = panel.company

export const carrierRoutes = {
  "pc.carriers": `${prefix}`,
};
