import React, {useEffect} from 'react'
import i18n from '../../i18n'
import {useSelectorAuth} from 'app/redux/actiontypes/auth/authSelectors';

export const I18nProvider = () => {

  const {language} = useSelectorAuth();
  useEffect(() => {
    i18n.changeLanguage(language?.languageCode?.toLowerCase());
  }, [language])

  return null;
}