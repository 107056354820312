import axios from 'axios';
import {BASE_URL_API} from "app/config/env";
import usePaginatedQuery2 from "app/hooks/usePaginatedQuery2";

const getListNotifications = (withPagination = true) => (key, page, results) => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/company-panel/notifications`,
  params: {
    PageNumber: withPagination ? page : 1,
    PageSize: withPagination ? results : 10,
  },
});

export const useNotifications = (enabled = true, withPagination = true) => usePaginatedQuery2({
  queryKey: ['notifications, list'],
  queryFn: getListNotifications(withPagination),
  config: {
    enabled,
    refetchInterval: 15000,
  },
});
