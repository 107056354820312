export const createSellerInfo = ({
  sellerContractorId = 0,
  sellerEmployeeId = 0,
  priceNet = 0,
  currencyId = 0
}) => {
  return ({
    sellerContractorId,
    sellerEmployeeId,
    priceNet,
    currencyId
  });
};
