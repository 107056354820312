export const updateSellerInfo = (state, {key, value}) => {

  //TODO: add key validation (or not?)

  return {
    ...state,
    sellerInfo: {
      ...state.sellerInfo,
      [key]: value,
    },
  };
}
