import React, {createContext, useContext} from 'react';
import useObjectState from "app/hooks/useObjectState";
import {Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import {ApiErrors} from "components/api/ApiErrors";
import { useTranslation } from "react-i18next";

const ConfirmContext = createContext({});

const initialState = (t) => ({
  isOpenConfirmModal: false,
  label: t('generic.deleteConfirm'),
  subLabel: null,
  btnLabel: t('generic.delete'),
  variant: 'danger',
  cancelBtnLabel: t('generic.cancel'),
  isProcessing: false,
  error: null,
  onConfirm: () => {},
  onFailure: () => {},
  onSuccess: () => {},
});

export const ConfirmProvider = ({children}) => {
  const { t } = useTranslation()
  const [
    {
      isOpenConfirmModal,
      label,
      subLabel,
      cancelBtnLabel,
      btnLabel,
      isProcessing,
      confirmFn,
      variant,
      error,
      onFailure: handleFailure,
      onSuccess: handleSuccess,
    },
    setState,
  ] = useObjectState(initialState(t));

  const deleteItem = async () => {
    if(!isProcessing) {
      setState({isProcessing: true});
      try {
        await confirmFn();
        setState({ isProcessing: false, isOpenConfirmModal: false });
        handleSuccess();
      } catch (e) {
        setState({ isProcessing: false, error: e });
        if(typeof handleFailure === 'function') handleFailure(e);
      }
    }
  };

  const handleCancel = (force = false) => {
    if(!isProcessing || force) {
      setState({ isOpenConfirmModal: false })
    }
  };

  const requestConfirm = ({
    confirmFn = () => {},
    onSuccess = () => {},
    onFailure = () => {},
    variant = 'danger',
    label = t('generic.deleteConfirm'),
    subLabel = null,
    cancelBtnLabel = t('generic.cancel'),
    btnLabel = t('generic.delete'),
  }) => {
    setState({
      ...initialState(t),
      confirmFn,
      onSuccess,
      onFailure,
      variant,
      label,
      subLabel,
      btnLabel,
      cancelBtnLabel,
      isOpenConfirmModal: true,
    });
  };

  return (
    <ConfirmContext.Provider value={{ requestConfirm, handleCancel, isProcessing, error, isOpenConfirmModal }}>
      {children}
      <Modal
        isOpen={isOpenConfirmModal}
        toggle={handleCancel}
      >
        <ModalHeader toggle={handleCancel} className={`bg-${variant}`}>
          <div className="py-1 pl-1">
            {label}
          </div>
        </ModalHeader>
        <ModalBody>
          <ApiErrors error={error}/>
          {subLabel && <div className="mt-25 mb-2">{subLabel}</div>}
          <div className="text-right">
            <button
              disabled={isProcessing}
              className="btn btn-outline-secondary mr-1"
              onClick={handleCancel}
            >
              {error ? 'Zamknij' : cancelBtnLabel}
            </button>
            {!error && <button
              disabled={isProcessing}
              className={`btn btn-${variant}`}
              onClick={deleteItem}
            >
              {btnLabel}
              {isProcessing && <Spinner color="white" size="sm" />}
            </button>}
          </div>
        </ModalBody>
      </Modal>
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {

  const context = useContext(ConfirmContext);
  if(!context) throw new Error('[useConfirm]: missing ConfirmProvider');

  const { requestConfirm, handleCancel:cancel, isProcessing, error, isOpenConfirmModal } = context;

  return [requestConfirm, cancel, {isProcessing, error, isOpenConfirmModal}];
}
