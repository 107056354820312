import {reducerUpdateChat} from "app/redux/chats&notifications/reducers/chat/reducerUpdateChat";
import {reducerCreateChat} from "app/redux/chats&notifications/reducers/chat/reducerCreateChat";

export const reducerCreateChats = (state, {chats}) => {
  if (chats?.length) {
    const chat = chats.shift();
    if (!chat?.id) {
      // error, chatId not exist
      return reducerCreateChats(state, {chats});
    }
    // create OR update chat
    const updatedState = state.chats[chat.id] ? (
      reducerUpdateChat(state, {chat})
    ) : (
      reducerCreateChat(state, {chat})
    );
    // recursion
    return reducerCreateChats(updatedState, {chats});
  }

  return state;
}

