import {useEffect} from "react";
import { useSelector } from "react-redux";

export const useSignalREvent = (connection, event, callback) => {
  const hasRole = useSelector(({auth}) => !!auth.login?.userRoles?.length);

  useEffect(() => {
    if (!hasRole) return
    if (connection.connectionState === 'Disconnected') {
      connection.start().then(() => {
        //console.log('connection started');
      });
    }
    // register event
    connection.on(event, callback);
    return () => {
      // unregister event
      connection.off(event, callback);
    }
  }, [hasRole]);
}
