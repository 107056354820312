import {createPlaceElement} from "app/redux/editOrder/utils/createPlaceElement";

export const setSinglePlace = (state, {placeId, orderPlace: passedOrderPlace}) => {

  const orderPlace = createPlaceElement(passedOrderPlace);

  return {
    ...state,
    orderPlaces: {
      ...state.orderPlaces,
      [placeId]: {
        ...orderPlace,
      },
    },
    orderPlacesKeys: [
      ...new Set([...state.orderPlacesKeys, placeId])
    ]
  };
}
