import {useSelector} from "react-redux";
import { useChatContext } from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";

export const useSelectChatInput = (chatId) => {
  const activeChatId = useChatContext()
  const id = chatId ?? activeChatId

  return useSelector(({ chats: { chats } }) => ({
    newMessage: id ? (chats[id]?.newMessage??'') : (''),
    fileMessage: id ? (chats[id]?.fileMessage??null) : (null),
  }));
}
