import React, {useState} from 'react';
import {
  Badge, Dropdown,
  DropdownItem as DropdownItemBootstrap,
  DropdownMenu,
  DropdownToggle,
  Media,
} from "reactstrap";
import * as Icon from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import {route} from "app/config/urls/routes";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useNotifications} from "app/crud/global/notifications/getList";
import Spinner from "components/@vuexy/spinner/Loading-spinner";
import {ApiErrors} from "components/api/ApiErrors";
import {NOTIFICATION_TYPES} from "app/config/enums/notification/notificationTypes";
import {useNotificationHub, useNotificationHubReceiveMessage} from 'app/hooks/signalR/useNotificationHub';
import useMoment from "app/hooks/useMoment";
import { useTranslation } from "react-i18next";

export const Notification = ({
  id,
  createdAt,
  notificationType,
  notificationRedirectType: redirectType,
  name,
  description,
  entityId,
  entity1Id,
  handleCloseDropdown,
}) => {
  const moment = useMoment()

  const NOTIFICATION = NOTIFICATION_TYPES[notificationType?.id];
  if (!NOTIFICATION) {
    console.error('Missing notification type', notificationType);// TODO: send error to backend
    return null;//TODO: display notification
  }
  return (
    <Link
      to={NOTIFICATION.redirectTo(entityId, entity1Id)}
      className="d-flex justify-content-between align-items-center w-100"
      onClick={handleCloseDropdown}
    >
      <Media className="d-flex align-items-center p-50">
        <Media left>
          <NOTIFICATION.Icon
            className={`font-medium-5 mr-50 ${NOTIFICATION.color}`}
            size={21}
          />
        </Media>
        <Media body className={`small m-0 p-0`} tag="h6">
          <div className="mb-25">{createdAt && (
            <time className="media-meta" dateTime={createdAt}>
              {moment(createdAt)?.fromNow()}
            </time>
          )}
          </div>
          {name}
          {description && <p className="notification-text">
            {description}
          </p>}
        </Media>
      </Media>
    </Link>
  )
}

export const Notifications = () => {
  const { t } = useTranslation()
  const {notifications} = useSelector(({notification}) => notification);
  const [isOpenDropdown, setIsOpen] = useState(false);

  const handleReceiveNotification = (a, b) => {
    console.log(a, b)
  }

  const [connection] = useNotificationHub();
  useNotificationHubReceiveMessage(connection, handleReceiveNotification)

  const {
    data,
    isFetching,
    status,
    error,
  } = useNotifications(isOpenDropdown, false);

  const handleToggleIsOpenDropdown = () => setIsOpen(!isOpenDropdown);

  const handleCloseDropdown = () => setIsOpen(false);

  return (
    <>
      <Dropdown
        isOpen={isOpenDropdown}
        toggle={handleToggleIsOpenDropdown}
        tag="li"
        className="dropdown-notification nav-item"
      >
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={21} />
          {!!notifications?.unreadCount && (
            <Badge pill color="primary" className="badge-up">
              {` ${notifications.unreadCount} `}
            </Badge>
          )}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          {status !== 'success' ? (
            isFetching || status === 'idle' ? <Spinner /> : <ApiErrors error={error} />
          ) : (
            <>
              <li className="dropdown-menu-header">
                <div className="dropdown-header mt-0">
                  <span className="notification-title">{t('generic.notifications')}</span>
                </div>
              </li>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative pt-25 pb-75"
                options={{
                  wheelPropagation: false
                }}
              >
                {data?.length ? (
                  data?.map(notification => (
                    <Notification
                      key={notification?.id}
                      handleCloseDropdown={handleCloseDropdown}
                      {...notification}
                    />
                  ))
                ) : (
                  <div className="py-2 text-center text-muted text-lowercase">
                    ({t('generic.noNotifications')})
                  </div>
                )}
                {/*<Notification name="Zlecenie utworzone" description="Kliknij aby przejść do edycji" notificationType={{id:1}}/>*/}
                {/*<Notification name="Zlecenie sprzedane" description="Firmie Wadas Żwirek i Muchomorek" notificationType={{id:2}}/>*/}
                {/*<Notification name="Zlecenie w realizacji" description="Realizacja z użyciem własnego pojazdu" notificationType={{id:3}}/>*/}
                {/*<Notification name="Zlecenie anulowane" description="Przez kupującego" notificationType={{id:4}}/>*/}
                {/*<Notification name="Witamy w Sydig!" description="Kliknij, aby poznać możliwości serwisu" notificationType={{id:90}}/>*/}
              </PerfectScrollbar>
              <li className="dropdown-menu-footer">
                <DropdownItemBootstrap
                  tag={Link}
                  onClick={() => setIsOpen(false)}
                  to={route['pc.settings.notifications']}
                  className="p-1 text-center"
                >
                  <span className="align-middle">{t('generic.viewAll')}</span>
                </DropdownItemBootstrap>
              </li>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
