import React from 'react';
import {RootRoute} from "app/router/components/RootRoute";
import {route} from "app/config/urls/routes";

const SellerPublicOrder = React.lazy(() => import('views/pages/shared/View_PublicOrder/View_PublicSellerOrder'));
const BuyerPublicOrder = React.lazy(() => import('views/pages/shared/View_PublicOrder/View_PublicBuyerOrder'));

export const sharedRoutes = [
  <RootRoute
    exact
    key="publicOrder"
    path={route['shared.orderContract.seller']()}
    fullLayout
    component={SellerPublicOrder}
  />,
  <RootRoute
    exact
    key="publicOrder"
    path={route['shared.orderContract.buyer']()}
    fullLayout
    component={BuyerPublicOrder}
  />,
];
