import React from "react";
import styled from "styled-components";
import {
  ChatContextProvider,
  useChatContext
} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";
import { MessageList } from "views/pages/companyPanel/conversations/View_Conversation/components/messages/MessageList";
import { MessageInput } from "views/pages/companyPanel/conversations/View_Conversation/components/messages/MessageInput";
import { useSelectChatHeader } from "app/redux/chats&notifications/selectors/selectChatHeader";
import Avatar from "components/@vuexy/avatar/AvatarComponent";
import { X } from "react-feather";
import { useDispatch } from "react-redux";
import { actionCloseChatbox, actionToggleChatbox } from "app/redux/chats&notifications/chatActions/actionChatbox";
import MessageDropzone
  from "views/pages/companyPanel/conversations/View_Conversation/components/messages/MessageDropzone";
import { useDropzone } from "react-dropzone";
import { actionSetNewTmpFile } from "app/redux/chats&notifications/chatActions/actionSetNewTmpFile";
import { useSelectChatInput } from "app/redux/chats&notifications/selectors/selectChatInput";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelectChatInfo } from "app/redux/chats&notifications/selectors/selectChatInfo";

const Container = styled.div`
  width: 300px;
  pointer-events: auto;
`

const ChatContainer = styled.div`
  height: 400px;
`

const MessageHeader = () => {
  const activeChatId = useChatContext()
  const { employee } = useSelectChatHeader(activeChatId);
  const {chat} = useSelectChatInfo(activeChatId);
  const dispatch = useDispatch()

  const handleClose = (e) => {
    e.stopPropagation()
    dispatch(actionCloseChatbox(activeChatId))
  }
  const handleToggle = () => {
    dispatch(actionToggleChatbox(activeChatId))
  }

  return (
    <div className={`position-relative align-items-center d-flex w-100 border-bottom cursor-pointer px-25 rounded-top ${!!chat.unreadCount ? 'bg-primary text-white' : ''}`} onClick={handleToggle}>
      <Avatar size="sm" img={employee?.photoUrl} />
      <div className="pl-25 font-weight-bold">{employee?.name ?? ''}</div>
      <div className={`ml-auto btn btn-link p-25 ${!!chat.unreadCount ? 'text-white' : ''}`} onClick={handleClose}>
        <X size={20} />
      </div>
    </div>
  );
}

const Chatbox = ({ chatId, open }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { fileMessage } = useSelectChatInput(chatId);

  const onDropAccepted = (files) => {
    if (!files?.[0]) return
    dispatch(actionSetNewTmpFile(chatId, files[0]));
  }
  const onDropRejected = () => {
    toast.error(t('message.attachmentError'))
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled: fileMessage,
    onDropAccepted,
    onDropRejected,
    accept: ['image/png', 'image/jpeg', 'image/svg+xml', 'video/mp4', 'video/quicktime', 'video/mpeg', 'application/pdf'],
  });

  return (
    <ChatContextProvider chatId={chatId}>
      <Container className="bg-theme rounded-top shadow small ml-50 border border-bottom-0">
        <MessageHeader />
        {open && (
          <ChatContainer {...getRootProps()} className="d-flex flex-column position-relative">
            <input {...getInputProps()} />
            <MessageList avatarSize="sm" />
            <MessageInput />
            <MessageDropzone {...{ isDragActive, isDragAccept }} />
          </ChatContainer>
        )}
      </Container>
    </ChatContextProvider>
  )
}

export default Chatbox
