export const MESSAGES_SET_UNREAD = 'messages.setCount';
export const NOTIFICATIONS_SET_UNREAD = 'notification.setCount';
export const SET_MENU_DATA = 'menuData.set';


export const dispatchMessagesSetUnread = (unreadCount, hasUnread) => ({
  type: MESSAGES_SET_UNREAD,
  payload: {
    hasUnread,
    unreadCount,
  },
});

export const dispatchNotificationSetUnread = (unreadCount) => ({
  type: NOTIFICATIONS_SET_UNREAD,
  payload: {
    unreadCount,
  },
});

export const dispatchSetMenuData = (unreadMessages, unreadNotifications) => ({
  type: SET_MENU_DATA,
  payload: {
    unreadMessages,
    unreadNotifications,
  },
});
