import {createPlaceCargo} from "app/redux/editOrder/utils/createPlaceCargo";

export const setSingleCargo = (state, {cargoId, orderCargo: passedOrderCargo}) => {

  const orderCargo = createPlaceCargo(passedOrderCargo);

  return {
    ...state,
    orderCargos: {
      ...state.orderCargos,
      [cargoId]: {
        ...orderCargo,
      },
    },
    orderCargosKeys: [
      ...new Set([...state.orderCargosKeys, cargoId])
    ]
  };
}
