import React, {Suspense} from 'react';
import Spinner from "components/@vuexy/spinner/Loading-spinner";
import {Route} from "react-router-dom";

const appRouteRender = (Component) => props => (
  <Suspense fallback={<Spinner />}>
    <Component {...props} />
  </Suspense>
);

export const AppRoute = ({
  component: Component,
  ...props
}) => {
  return (
    <Route
      {...props} // can overwrite exact
      render={appRouteRender(Component)}/>
  );
}
