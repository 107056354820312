import React from "react"
import Router from "app/router/Router"
import {SydigProvider} from "app/context/SydigProvider";
import './i18n';
import {CookiesProvider} from 'react-cookie';
// css
import "./components/@vuexy/rippleButton/RippleButton"
import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"
import "flag-icon-css/css/flag-icon.min.css"
import 'react-toastify/dist/ReactToastify.css';
import {SignalRProvider} from "app/context/signalR/SignalRProvider";
import {I18nProvider} from "app/context/I18nProvider";

const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
  }
}

const App = () => {
  return (
    <CookiesProvider>
      <SydigProvider>
        <SignalRProvider>
          <I18nProvider />
          <Router />
        </SignalRProvider>
      </SydigProvider>
    </CookiesProvider>
  );
}

export default App
