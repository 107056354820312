export const reducerToggleChatbox = (state, { chatId }) => {
  const index = state.chatboxes.findIndex(({ id }) => id === chatId)

  if (index < 0) return state

  return {
    ...state,
    chatboxes: [
      ...state.chatboxes.slice(0, index),
      { id: chatId, open: !state.chatboxes[index].open },
      ...state.chatboxes.slice(index + 1),
    ],
  };
}
