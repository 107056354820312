import useObjectState from "app/hooks/useObjectState";

const defaultState = () => ({
  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
});

export const useRequest = (requiredReset = true) => {
  const [state, setState] = useObjectState(defaultState());
  const {
    isLoading,
    error,
    isError,
    isSuccess,
  } = state;

  const isAvailable = isLoading===false && (isSuccess===false || requiredReset===false);

  const request = async (fn, onSuccess=()=>{}, onError=()=>{}, force = false) => {
    if(isAvailable) {
      setState({ isLoading: true, isSuccess: false, isError: false, error: null });
      try {
        const response = await fn();
        setState({ isLoading: false, isSuccess: true, isError: false, error: null });
        onSuccess(response);
      } catch (error) {
        console.log(error)
        setState({ isLoading: false, isSuccess: false, isError: true, error });
        onError(error);
      }
    }
  }

  const reset = () => {
    setState(defaultState());
  }

  return [
    // functions
    { request, reset },
    // state
    { isAvailable, isLoading, isSuccess, isError, error},
  ];

};
