import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist";
import {chatsReducer} from "app/redux/chats&notifications/chatsReducer";

const persistConfig = {
  key: 'sydig.toggle.chat',
  storage,
  whitelist: ['isSidebarOpen'],
}

export default persistReducer(persistConfig, chatsReducer);
