import React, {useState} from 'react';
import {ArrowLeft, ArrowRight, Download, X} from 'react-feather';
import {Modal, Spinner, Spinner as LoadingSpinner} from "reactstrap";
import {getFile} from 'app/crud/companyPanel/order/__OLD__files/getFile';
import {useSelectorAuth} from 'app/redux/actiontypes/auth/authSelectors';
import styled from 'styled-components';
import {useSingleMessage} from 'app/crud/companyPanel/messages/getSingle';
import {useParams} from 'react-router';


const StyledSpinnerWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Arrow = ({icon, onClick}) =>
  <div
    className="bg-white p-1 rounded-circle cursor-pointer"
    onClick={onClick}>
    {icon}
  </div>

const CloseButton = ({closeModal}) =>
  <div
    className="bg-white p-1 rounded-circle cursor-pointer ml-50"
    onClick={closeModal}
  >
    <X />
  </div>

const DownloadButton = ({handleDownload, isFileDownloading}) =>
  <div
    className="bg-white p-1 rounded-circle cursor-pointer "
    onClick={handleDownload}
  >
    {isFileDownloading
      ? (
        <StyledSpinnerWrapper>
          <Spinner size="sm" />
        </StyledSpinnerWrapper>
      ) : (
        <Download />
      )
    }
  </div>

export const ImageMessageModal = ({isOpenModal, closeModal, id}) => {

  const {chatId} = useParams();
  const [currentMessageId, setMessageId] = useState(id);
  const {token} = useSelectorAuth();
  const {data: message, isFetching} = useSingleMessage(chatId, currentMessageId);

  const goNext = () => {
    setMessageId(message?.nextMessagePhotoId)
  }

  const goPrev = () => {
    setMessageId(message?.prevMessagePhotoId)
  }

  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const handleDownload = async (e) => {
    setIsFileDownloading(true);
    e.preventDefault();
    try {
      await getFile(token, null, null, message.downloadUrl, undefined, undefined);
      setIsFileDownloading(false);
    } catch (e) {
      console.error('error', e);
    }
  }

  const handleClose = () => {
    setMessageId(id);
    closeModal();
  }
  return (
    <Modal className="modal-message" toggle={closeModal} isOpen={isOpenModal}>
      <div className="message-image-close d-flex flex-row">
        <DownloadButton handleDownload={handleDownload} isFileDownloading={isFileDownloading} />
        <CloseButton closeModal={handleClose} />
      </div>
      <div className="d-flex align-items-center">
        {message?.prevMessagePhotoId &&
          <Arrow
            icon={<ArrowLeft />}
            onClick={goPrev}
          />
        }
      </div>
      <div className="p-2 d-flex justify-content-center align-items-center">
        {isFetching
          ? (
            <LoadingSpinner />
          ) : (
            <img
              key={message?.fileUrl}
              src={message?.fileUrl}
              className='mh-90vh mw-100'
            />
          )
        }
      </div>
      <div className="d-flex align-items-center">
        {message?.nextMessagePhotoId &&
          <Arrow
            icon={<ArrowRight />}
            onClick={goNext}
          />
        }
      </div>
    </Modal>
  );
};
